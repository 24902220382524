import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import PageNotFound from "@/components/PageNotFound";
import LoginPage from "@/views/LoginPage";
import { getCurrentUser } from '@/authService';
import store from '@/store/index.js';

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/non_resident",
    name: "NonResident",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/NonResident.vue"),
  },
  {
    path: "/ebook",
    name: "BukukokuPage",
    meta: { requiresAuth: true, },
    component: () => import("../views/BukukokuPage.vue"),
  },
  {
    path: "/check-payment/:id",
    name: "CheckPayament",
    meta: { requiresAuth: true, },
    component: () => import("../views/CheckPaymentPage.vue"),
  },
  {
    path: "/bookpage/:id",
    name: "BookPage",
    meta: { requiresAuth: true, },
    component: () => import("../views/BookPage.vue"),
  },
  {
    path: "/sponsor",
    name: "SponsorPage",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/SponsorPage.vue"),
  },
  {
    path: "/vote",
    name: "VotePage",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/VotePage.vue"),
  },
  {
    path: "/voting",
    name: "VotingPage",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/VotingPage.vue"),
  },
  {
    path: "/sulung",
    name: "SulungPage",
    meta: { requiresAuth: true, noFooter: false },
    component: () => import("../views/SulungPage.vue"),
  },
  {
    path: "/profile",
    name: "ProfilePage",
    meta: { requiresAuth: true },
    component: () => import("../views/ProfilePage.vue"),
  },
  {
    path: "/wifi",
    name: "WifiPage",
    meta: { requiresAuth: true },
    component: () => import("../views/WifiPage.vue"),
  },
  {
    path: "/timetable",
    name: "TimetablePage",
    meta: { requiresAuth: true },
    component: () => import("../views/TimetablePage.vue"),
  },
  {
    path: "/impersonate",
    name: "ImpersonatePage",
    meta: { requiresAuth: true },
    component: () => import("../views/ImpersonatePage.vue"),
  },
  {
    path: "/qr_scanner",
    name: "QrScanner",
    meta: { requiresAuth: true },
    component: () => import("../views/QrScanner.vue"),
  },
  {
    path: "/virtual_card",
    name: "VirtualCard",
    meta: { requiresAuth: true },
    component: () => import("../views/VirtualCard.vue"),
  },
  {
    path: "/transport/:id",
    name: "TransportPage",
    meta: { requiresAuth: true },
    component: () => import("../views/TransportPage.vue"),
  },
  {
    path: "/iderms/:id",
    name: "IdermsPage",
    meta: { requiresAuth: true },
    component: () => import("../views/IdermsPage.vue"),
  },
  /*{
    path: "/checkin/:id",
    name: "CheckinPage",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/CheckinPage.vue"),
  },*/
  {
    path: "/daftar",
    name: "DaftarPage",
    meta: { requiresAuth: true, },
    component: () => import("../views/DaftarPage.vue"),
  },
  
  {
    path: "/saring",
    name: "Saring",
    meta: { requiresAuth: true },
    component: () => import("../views/SaringPage.vue"),
  },
  {
    path: "/result",
    name: "Result",
    meta: { requiresAuth: true },
    component: () => import("../views/ResultPage.vue"),
  },
  {
    path: "/vaccination",
    name: "Vaccination",
    meta: { requiresAuth: true },
    component: () => import("../views/VaccinationPage.vue"),
  },  
  {
    path: "/kad-prihatin",
    name: "KadPrihatinPage",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/KadPrihatinPage.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  },
  {
    path: "/event/:id",
    name: "eventSingle",
    component: () => import("../views/EventSingle.vue"),
  },
  {
    path: "/login",
    name: "LoginPage",
    meta: { noHeader: true, noFooter: true, noMenu: true },
    component: LoginPage,
  },
  {
    path: "/websso/callback",
    name: "SsoPage",
    meta: { noHeader: true, noFooter: true, noMenu: true },
    component: () => import("../views/SsoPage.vue"),
  },
  {
    path: "/ikrar",
    name: "IkrarPage",
    meta: { noHeader: false, noFooter: false, noMenu: false },
    component: () => import("../views/IkrarPage.vue"),
  },
  {
    path: "/egl",
    name: "GlPage",
    meta: { noHeader: false, noFooter: false, noMenu: false },
    component: () => import("../views/GlPage.vue"),
  },
  
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { noHeader: true, noFooter: true, noMenu: true },
  },
  {
    path: "/masmed2u",
    name: "HomeMASMED2u",
    meta: { requiresAuth: true },
    component: () => import("../views/masmed2u/HomePageMasmed.vue"),
  },
  {
    path: "/myent",
    name: "myEnt",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/masmed2u/MyEnt.vue"),
  },
  {
    path: "/tks",
    name: "TKS",
    meta: { requiresAuth: true, noFooter: true },
    component: () => import("../views/masmed2u/TKS.vue"),
  },
  {
    path: "/bill",
    name: "Bill",
    meta: { requiresAuth: true },
    component: () => import("../views/masmed2u/Bill.vue"),
  },
  {
    path: "/program/:typeProgram",
    name: "Program",
    meta: { requiresAuth: true },
    component: () => import("../views/masmed2u/Program.vue"),
  },
  {
    path: "/addProgram/:typeProgram",
    name: "AddProgram",
    meta: { requiresAuth: true },
    component: () => import("../views/masmed2u/AddProgram.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const router = new Router({
//   mode: 'history',
//   linkExactActiveClass: 'active',
//   base: process.env.BASE_URL,
//   routes: routes
// })

router.beforeEach(async (to, from, next) => {
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (to.name !== 'LoginPage') {
    localStorage.setItem('mystudent-from', to.fullPath);
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const loggedIn = localStorage.getItem('user');
  //if (to.matched.some((record) => record.meta.requiresAuth && !loggedIn)) {
  if (requiresAuth) {
    if(!(await getCurrentUser())){
      localStorage.removeItem('user');
        next('/login');
    }else if(!loggedIn){
      const user = await getCurrentUser()
      store.dispatch("auth/login", user.email.split("@")[0]).then(
                        (result) => {
                            if (result) {

                                next()
                            }

                            else
                                next('/login');
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
    }
    next()
    
  }else {
    
    next();
  }
});


export default router;
