import { createApp } from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import './registerServiceWorker'
import router from './router';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/style.css';
import './assets/css/custom.css';
import store from './store/index'

window.moment = require('moment');
moment.locale('ms-my'); 
const app = createApp(App)
app.config.ignoredElements  = tag => tag.startsWith('ion-');
app.use(VueAxios, axios)
app.use(router).use(store).mount('#app')
import 'bootstrap/dist/js/bootstrap.js'