import axios from 'axios';
import { doc, getDoc } from "firebase/firestore";
import {db} from "../firebase"
const API_URL = 'http://localhost:8080/api/test/';

class UserService {
  async getPublicContent() {
    
    const settings = await getDoc(
      doc(db, "mystudent", "setting")
    );
    
    if (settings.exists()) {
      //console.log(JSON.stringify(settings.data()))
      return settings.data()
    } else {
      return {message:"Data Not Exist"}
    }
  }

  async getPublicContentMasmed() {
    
    const settings = await getDoc(
      doc(db, "masmed", "setting")
    );
    
    if (settings.exists()) {
      return settings.data()
    } else {
      return {message:"Data Not Exist"}
    }
  }

  getUserBoard() {
    return axios.get(API_URL + 'user');
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod');
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin');
  }
}

export default new UserService();