<template>
  
  <div id="app" class="mainApp"> 
    <HeaderBar v-if="!$route.meta.noHeader" />
    <router-view />
    <BottomMenu v-if="!$route.meta.noFooter"  />
    <div class="toast-box toast-bottom bg-primary" :class="updateExists">
        <div class="in">
            <div class="text text-white">
                Terdapat kemaskini terbaru.
            </div>
        </div>
        <button @click="refreshApp" class="btn btn-sm btn-text text-white btn-outline-light">Kemaskini Sekarang<i
                class="bi bi-arrow-right-short"></i></button>
    </div>
    <!-- iOS Add to Home Action Sheet -->
    <div class="offcanvas offcanvas-bottom action-sheet inset ios-add-to-home" :class="deferredPromptIphone" tabindex="-1"
            id="ios-add-to-home-screen">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title">Add to Home Screen</h5>
                <a href="#" class="close-button" data-bs-dismiss="offcanvas" @click="dismiss">
                    <ion-icon name="close"></ion-icon>
                </a>
            </div>
            <div class="offcanvas-body">
                <div class="action-sheet-content text-center">
                    <div class="mb-1"><img src="/img/icons/android-chrome-192x192.png" alt="image" class="imaged w48">
                    </div>
                    <h4>MyStudent</h4>
                    <div>
                        Install MyStudent on your iPhone's home screen.
                    </div>
                    <div>
                        Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen 
                    </div>
                </div>
            </div>
        </div>
        <!-- * iOS Add to Home Action Sheet -->


        <!-- Android Add to Home Action Sheet -->
        <div class="offcanvas offcanvas-top action-sheet inset android-add-to-home" :class="deferredPromptAndroid" tabindex="-1"
            id="android-add-to-home-screen">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title">Add to Home Screen</h5>
                <a href="#" class="close-button" data-bs-dismiss="offcanvas" @click="dismiss">
                    <ion-icon name="close"></ion-icon>
                </a>
            </div>
            <div class="offcanvas-body">
                <div class="action-sheet-content text-center">
                    <div class="mb-1"><img src="/img/icons/android-chrome-192x192.png" alt="image" class="imaged w48">
                    </div>
                    <h4>MyStudent</h4>
                    <div>
                        Install MyStudent on your Android's home screen.
                    </div>
                    <div>
                        Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.
                    </div>
                </div>
            </div>
        </div>
        <!-- * Android Add to Home Action Sheet -->
    
  </div>
</template>
<script>
  import BottomMenu from './components/partials/BottomMenu.vue';
  import HeaderBar from './components/partials/HeaderBar.vue';
  import update from './mixins/update'
  import Cookies from "js-cookie";
  export default {
    name: 'app',
    components: {
      BottomMenu,
      HeaderBar,
    },
    mixins: [update],
    data() {
      return {
        deferredPromptAndroid: '',
        deferredPromptIphone: ''
      };
    },
    created() {
    window.addEventListener("beforeinstallprompt", e => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          if (Cookies.get("add-to-home-screen") === undefined) {
            if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
              this.deferredPromptAndroid = 'show';
            }else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
              this.deferredPromptIphone = 'show';
            }
          }
        });
    window.addEventListener("appinstalled", () => {
        this.deferredPromptAndroid = null;
          this.deferredPromptIphone = null;
        });
      },
      methods: {
        async dismiss() {
          Cookies.set("add-to-home-screen", null, { expires: 15 });
          this.deferredPromptAndroid = null;
          this.deferredPromptIphone = null;
        },
        async install() {
          if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
              this.deferredPromptAndroid = 'show';
            }else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
              this.deferredPromptIphone = 'show';
            }
        }
      }
  };
</script>