<template>
<div class="error-page">
    <div class="icon-box text-danger">
        <i class="bi bi-exclamation-circle-fill"></i>
    </div>
    <h1 class="title">PAGE NOT FOUND</h1>
    <div class="text mb-5">
        Maaf, laman tidak dijumpai. Sila kembali ke <router-link to="/">Anjung</router-link>
    </div> 

    
</div>
</template>

<script>
export default {
  name: 'page_not_found'
}
</script>
